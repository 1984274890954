<template>
    <b-modal ref="modal" size="sm" centered hide-footer lazy>
        <div v-if="cumStock">
            <div v-if="storageCustomer.id && storageCustomer.id !== customer.id">
                <p class="text-danger mb-0">
                    Este medicamento no hace parte de la misma farmacia. Para agregarlo, ingresa al carrito y elimina el registro actual.
                </p>
            </div>
    
            <div v-else>
                <p>Ingresa la cantidad de <b> {{cumStock.cum.producto}} </b> a solicitar: </p>
                <b-input-group>
                    <b-form-input placeholder="Cantidad a pedir" type="number" v-model="amount" @keyup.enter="setCumAmount"/>
                    <template #append>
                        <b-button variant="primary" @click="setCumAmount" size="sm">
                            <b-icon icon="cart-plus-fill"/>
                        </b-button>
                    </template>
                </b-input-group>
            </div>
        </div>
    </b-modal>    
</template>

<script>
    import {mapMutations, mapActions, mapState} from '@/store/deliveries';

    export default {
        props: {
            customer: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                cumStock: null,
                amount: null,
            }
        },
        computed: {
            ...mapState({
                storageCustomer: 'customer', 
                storageStock: 'cumStock',
            }),
        },
        watch: {
            cumStock({id}){
                this.amount = this.storageStock[id]?.amount;
            }
        },
        methods: {
            ...mapMutations(['setCumStock', 'setCustomer']),
            ...mapActions(['saveInStorage']),

            show(cumStock){
                this.cumStock = cumStock;
                this.$refs.modal.show();
            },

            setCumAmount(){
                const {amount, cumStock} = this;
                const cum = cumStock.cum;

                this.setCumStock({
                    stock_id: cumStock.id,
                    amount: Number(amount),
                    cum,
                });

                if(!this.storageCustomer.id){
                    this.setCustomer(this.customer);
                }

                this.saveInStorage();

                this.$refs.modal.hide();
                if(amount > 0){
                    this.showToast(`${cum.producto} se ha cargado al carrito con ${amount} unidades`);
                } else {
                    this.amount = null;
                    this.showToast(`Se ha eliminado el medicamento ${cum.producto} del carrito`, 'danger');
                }
            },

            showToast(msg, variant='success'){
                this.$bvToast.toast(msg, {
                    variant, 
                    solid: true,
                });
            }
        }
    }
</script>

<template>
  <div class="d-flex flex-column gap-5">
    <b-container 
        class="
          d-flex flex-column flex-md-row 
          transition transform-up
          justify-content-between align-items-md-center gap-5
          bg-white shadow rounded p-2
        "
        v-for="cum in cums.hits"
        :key="cum.id"
    >
        <div>
            <h4 class="bold"> {{ cum.cum.producto }} </h4>
            <p class="mb-0">
                <b>Cantidad:</b> {{ cum.cum.quantity }} Unidades
            </p>
            <p class="mb-0">
                <b>Principio Activo:</b> {{ cum.cum.principioactivo }}
            </p>
            <p class="mb-0">
                <b>Forma:</b> {{ cum.cum.formafarmaceutica }}
            </p>
        </div>

        <div class="d-flex gap-2 justify-content-end">
            <b-button type="button" class="btn-sm" @click="verMas(cum)"> 
                <b-icon icon="eye-fill" />
                Ver más
            </b-button>
            <b-button v-if="allowSepare && cum.separe" @click="separeModalView(cum)" variant="info" size="sm">
                <b-icon icon="bookmarks-fill" />
                Reservar / Separar
            </b-button>
            <b-button v-if="allowAddCart" variant="success" @click="addCart(cum)" size="sm">
                <b-icon icon="cart-plus-fill"></b-icon>
                Agregar al domicilio
            </b-button>
        </div>
    </b-container>
    
    <b-modal ref="modalDetail" hide-footer>
      <div class="d-block"  v-if="cumDetail.cum">
        <h3 class="text-center">Detalle Producto</h3>

        <b>Descripcioncomercial :</b>
        {{ cumDetail.cum.descripcioncomercial }} <br />
        <b>Descripcionatc :</b> {{ cumDetail.cum.descripcionatc }}
        <br />
        <b>Unidadmedida :</b> {{ cumDetail.cum.unidadmedida }} <br />
        <b>Unidadreferencia :</b> {{ cumDetail.cum.unidadreferencia }}
        <br />
        <b>Unidad :</b> {{ cumDetail.cum.unidad }} <br />
        <b>Concentracion :</b> {{ cumDetail.cum.concentracion }} <br />
        <b>Fecha Actualización :</b> {{ cumDetail.updated_at }} <br />
      </div>
      <b-button class="mt-3 btn-sm" block @click="hideModal">Cerrar</b-button>
    </b-modal>
     <b-modal ref="modalSepareMedicine" hide-footer >
        <form-separe-medicine @cerrarModalNotificacion="cerrarModalNotificacion" :search="search" :cumDetail="cumDetail"></form-separe-medicine>
     </b-modal>

    <CumAddCart ref="cumAddCart" :customer="client"/>
  </div>
</template>
<script>
import moment from 'moment';
import FormSepareMedicine from "./SeparateMedication.vue";
import CumAddCart from './CumAddCart.vue';
export default {
  name: "CumForm",
  props: {
    cums: {
      type: Object,
    },
    search:{
      type: Object,
    },
    client:{
      type: Object,
    }
  },
  components: {
    FormSepareMedicine,
    CumAddCart,
  },
  data() {
    return {
      cumDetail: {},
      activeBtnSepare:false
    };
  },
     watch:{
    search(firts,old){
      console.log(firts,old);
    }
  },
  computed:{
    allowSepare(){
      return this.client?.services?.includes('Consulta');
    },
    
    allowAddCart(){
      return this.client?.services?.includes('Domicilios');
    },
  },
  methods: {
    verMas(cum) {
      this.cumDetail = cum;
      this.cumDetail.updated_at =this.cumDetail.updated_at.split('.')[0].replace("T"," ");
      this.$refs["modalDetail"].show();
    },
    separeModalView(cum) {
      this.cumDetail = cum;
      this.$refs["modalSepareMedicine"].show();
    },
    addCart(cumStock){
      this.$refs.cumAddCart.show(cumStock);
    },
     hideModal() {
      this.$refs["modalDetail"].hide();
    },
     cerrarModalNotificacion(){
      this.$refs["modalSepareMedicine"].hide();
    }
  },
};
</script>

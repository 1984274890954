
<template>
  <div>
    <h2>Separar medicamentos</h2>
    <b-form-group label="Tipo Documento:" label-for="input-3">
      <v-select
        v-model="separe.documentType"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :clearable="false"
        label="description"
        placeholder="-- Por favor seleccione una opcion --"
        :options="documentsType"
        :reduce="(option) => option.id"
      />
      <small v-if="errors['documentType']" class="text-danger">{{
        errors["documentType"][0]
      }}</small>
    </b-form-group>
    <b-form-group id="input-group-2" label="Documento" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="separe.document"
        placeholder="Documento"
        required
      ></b-form-input>
      <small v-if="errors['document']" class="text-danger">{{
        errors["document"][0]
      }}</small>
    </b-form-group>
    <b-form-group id="input-group-2" label="Nombre" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="separe.name_client"
        placeholder="Nombre"
        required
      ></b-form-input>
      <small v-if="errors['name_client']" class="text-danger">{{
        errors["name_client"][0]
      }}</small>
    </b-form-group>

    <b-form-group id="input-group-2" label="Apellidos" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="separe.surnames"
        placeholder="Apellido"
        required
      ></b-form-input>
      <small v-if="errors['surnames']" class="text-danger">{{
        errors["surnames"][0]
      }}</small>
    </b-form-group>

    <b-form-group id="input-group-2" label="Cantidad" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="separe.quantity_reserve"
        placeholder="Cantidad"
        required
      ></b-form-input>
      <small v-if="errors['quantity_reserve']" class="text-danger">{{
        errors["quantity_reserve"][0]
      }}</small>
    </b-form-group>

    <b-form-group
      id="input-group-2"
      label="Teléfono"
      label-for="input-2"
    >
      <b-form-input
        id="input-2"
        v-model="separe.phone"
        placeholder="telefono"
        required
      ></b-form-input>
      <small v-if="errors['phone']" class="text-danger">{{
        errors["phone"][0]
      }}</small>
    </b-form-group>
    <b-form-checkbox
      id="checkbox-1"
      v-model="separe.processingPersonalData"
      name="checkbox-1"
      unchecked-value="not_accepted"
    >
        Acepto los
        <a href="https://indiju.com.co/index.php/terminos-y-condiciones/" target="_blank">terminos y condiciones</a>
        y la
        <a href="https://indiju.com.co/index.php/politica-de-privacidad/" target="_blank">politica de privacidad</a>
    </b-form-checkbox>
    <small v-if="errors['processingPersonalData']" class="text-danger">{{
      errors["processingPersonalData"][0]
    }}</small>

    <div v-if="errors['date']" class="p-1 alert alert-warning mt-1" show variant="warning">{{
      errors["date"][0]
    }}</div>
    <b-button class="btn-sm mt-1" block @click="enviarSepare"
      >Enviar</b-button
    >
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BButton,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  props: {
    search: {
      type: Object,
    },
    cumDetail: {
      type: Object,
    },
  },
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BButton,
    BFormRadioGroup,
    BFormSelect,
    BFormSelectOption,
    vSelect,
  },
  data() {
    return {
      separe: {
        documentType: "",
        processingPersonalData: false,
        date:true
      },
      errors: [],
      documentsType: [],
    };
  },
  methods: {

    getDocuments() {
      this.$http.get(`/documenttypes`).then((res) => {
        this.documentsType = res.data.document_types;
      });
    },
    enviarSepare() {
      let notificationRequest = Object.assign(this.separe, this.cumDetail);
      const _this = this;
      this.errors = [];
      this.$http
        .post(`/cum/separe`,notificationRequest)
        .then((res) => {
          _this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Notificación`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Se ha realizado la separación de su medicamento con exito, No #${res.data.id}`,
            },
          });
          _this.$emit("cerrarModalNotificacion");
        })
        .catch(function (error) {
          _this.errors = error.response.data.errors;
        });
    },
  },
  created() {
    this.getDocuments();
  },
};
</script>

<template>
  <div class="d-flex flex-column gap-5">
    <b-container
        class="
          d-flex flex-column flex-md-row 
          transition transform-up
          justify-content-between align-items-md-center gap-5
          bg-white shadow rounded p-2
        "
        v-for="cum in cums.hits"
        :key="cum.id"
    >
      <div>
          <h4 class="bold"> {{ cum.producto }} </h4>
          <p class="mb-0">
              <b>Principio Activo:</b> {{ cum.principioactivo }}
          </p>
          <p class="mb-0">
              <b>Forma:</b> {{ cum.formafarmaceutica }}
          </p>
      </div>

      <div class="d-flex gap-2 justify-content-end">
        <b-button type="button" @click="verMas(cum)" size="sm"> 
            <b-icon icon="eye-fill" />
            Ver más
        </b-button>

        <b-button @click="notificarModalView(cum)" variant="info" size="sm">
            <b-icon icon="bell-fill" />
            Notificame
        </b-button>
      </div>
    </b-container>

    <b-modal ref="modalDetailCum" hide-footer >
      <div class="d-block" v-if="cumDetail">
        <h3 class="text-center">Detalle Producto</h3>

        <b>Descripcioncomercial :</b>
        {{ cumDetail.descripcioncomercial }} <br />
        <b>Descripcionatc :</b> {{ cumDetail.descripcionatc }}
        <br />
        <b>Unidadmedida :</b> {{ cumDetail.unidadmedida }} <br />
        <b>Unidadreferencia :</b> {{ cumDetail.unidadreferencia }}
        <br />
        <b>Unidad :</b> {{ cumDetail.unidad }} <br />
        <b>Concentracion :</b> {{ cumDetail.concentracion }} <br />
      </div>
      <b-button class="mt-3 btn-sm" block @click="hideModal">Cerrar</b-button>
    </b-modal>

     <b-modal ref="modalNotificar" hide-footer >
        <form-notification @cerrarModalNotificacion="cerrarModalNotificacion" :search="search" :cumDetail="cumDetail"></form-notification>
     </b-modal>
    
  </div>
</template>
<script>
import formNotification from "./notification.vue";
export default {
  name: "CumForm",
  props: {
    cums: {
      type: Object,
    },
    search:{
      type: Object,
    }
  },
  components: {
    formNotification
  },
  data() {
    return {
      cumDetail: {}
    };
  },
 
  methods: {
    verMas(cum) {
      this.cumDetail = cum;
      this.$refs["modalDetailCum"].show();
    },
     hideModal() {
      this.$refs["modalDetailCum"].hide();
    },
    notificarModalView(cum) {
       this.cumDetail = cum;
      this.$refs["modalNotificar"].show();
    },
    cerrarModalNotificacion(){
      this.$refs["modalNotificar"].hide();
    }
  },
  
};
</script>
